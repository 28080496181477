import React from "react";
import { graphql } from "gatsby";
import { saveAs } from "file-saver";
import { useIntl } from "gatsby-plugin-intl";

import Layout from "../../components/layout";
import HeroWithImage from "../../components/heroWithImage/index";
import BusinessModel from "../../containers/about-us/BusinessModel";
import GoldBox from "./../../components/goldbox";
import Seo from "../../components/seo";

import heroDesktop from "../../assets/heroDesktop/BusinessModel.png";
import heroMobile from "../../assets/heroMobile/BusinessModel.png";

const OurBusinessModel = ({ data }) => {
  const intl = useIntl();
  const presentation = data?.presentation?.nodes[0];

  const openThankModal = async (file) => {
    if (typeof window != "undefined") {
      const downloadResult = await fetch(file.url);
      const blob = await downloadResult.blob();
      saveAs(blob, file.fileName);
    }
    setTimeout(() => {}, 1000);
  };

  return (
    <Layout inverted>
      <Seo title="BUSINESS MODEL" />
      <HeroWithImage
        title={intl.locale === "fr" ? "MODÈLE D'AFFAIRE" : "BUSINESS MODEL"}
        heroMobile={heroMobile}
        heroDesktop={heroDesktop}
      />
      <BusinessModel data={data.model.nodes[0]} />
      {presentation && (
        <GoldBox
          title={data?.presentation?.nodes[0]?.title}
          main={presentation?.content}
          button={presentation?.ctaButtonText}
          link={presentation?.ctaButtonLink}
          file={presentation?.downloadDocument?.file}
          onDownloadClick={openThankModal}
          blue
        />
      )}
    </Layout>
  );
};

export const query = graphql`
  query OurBusinessModelQuery($locale: String) {
    model: allContentfulOurBusinessModelBusinessModel(filter: { node_locale: { eq: $locale } }) {
      nodes {
        title
        description {
          raw
        }
        modelImage {
          file {
            url
          }
        }
      }
    }

    presentation: allContentfulHomeCorporatePresentation(
      filter: { node_locale: { eq: $locale }, hide: { eq: false } }
    ) {
      nodes {
        title
        content {
          raw
        }
        ctaButtonLink
        ctaButtonText
        downloadDocument {
          file {
            url
            fileName
          }
        }
      }
    }
  }
`;

export default OurBusinessModel;
